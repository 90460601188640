<template>
  <div class="Technology">
    <!-- <h3>技术领域</h3> -->
    <div class="technologyItem">
      <div class="title" v-if="title !='行业领域' ">技术领域</div>
      <div class="title" v-if="title =='行业领域' ">{{title}}</div>
      <div class="btnList">
        <span
          @click="selectTagHandle('all')"
          :class="['el-btn', activeTag === 'all' ? 'active': 'no-active']"
          data-index="all"
          >全部</span>
        <span
          v-for="(item, key, index) in techfieldList"
          :data-index="item"
          :key="index + key"
          :class="['el-btn', activeTag === item ? 'active' : 'no-active' ]"
          @click="selectTagHandle(item)"
          >{{ key }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props:['title'],
  computed: {
    ...mapGetters(["techfieldList"]),
  },
  data() {
    return {
      activeTag: "all",
      industryList:{
        石油化工:"2",
        智能装备:"3",
        建材家居:"4",
        饮料食品:"5",
        电子信息:"6",
        新材料:"7",
        新能源:"8",
        生物医疗:"9",
        数字服务:"10",
        商贸物流:"11",
        文化旅游:"12",
        健康服务:"13",
        金融服务:"14",
        环保产业:"15",
      }
    };
  },
  created() {
    console.log("技术领域数据是:",this.techfieldList)
    console.log("我新加的字段:",this.industryList)
  },
  methods: {
    selectTagHandle(val) {
      console.log("");
      this.activeTag = val;
      let tech = val === "all" ? "" : val;
      this.$emit("techHandle", tech);
    },
  },
};
</script>
<style scoped>
.Technology {
  /* padding: 10px 15px; */
  width: 100%;
  height: 112px;
  /* border: 1px solid #f2f2f2; */
  /* border-radius: 5px; */
}
.technologyItem {
  height: 112px;
  width: 100%;
  box-sizing: border-box;
  padding: 24px 30px 24px 0px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #F1F1F1;
  border-bottom: 1px solid #F1F1F1;
}

.title {
  width: 88px;
  height: 21px;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
  border-right: 1px solid #E5E5E5;
  margin-right: 16px;
}
h3 {
  margin: 24px 0;
  font-size: 24px;
  font-weight: bold;
  color: #101010;
}
.el-btn {
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0 16px 8px 0px;
  padding: 5px 8px;
  /* line-height: 21px; */
  height: 24px;
  /* width: 44px; */
  font-size: 14px;
  /* font-family: SourceHanSansCN-Regular, SourceHanSansCN; */
  font-family: 思源黑体 CN Regular;
  font-weight: 400;
  color: #101010;
}
/* .el-btn:hover {
  color: #2885FF;
  border: none;
} */
.active {
  /* height: 38px; */
  background: #F5F9FF;
  border-radius: 6px;
  color: #2885FF;
  border: 1px solid #2885FF;
}


.no-active {
  border: none;
  color: #404040;
  background-color: #FFFFFF;
}
.no-active:hover {
  color: #2885FF;
  border: none;
}
</style>